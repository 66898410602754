<template>
  <b-card>
    <b-row class="justify-content-between">
      <b-col class="pr-md-32 pr-md-120">
        <h4>Buttons</h4>

        <p class="hp-p1-body">
          Use spinners within buttons to indicate an action is currently
          processing or taking place. You may also swap the label text out of
          the spinner element and utilize button text as needed.
        </p>
      </b-col>

      <b-col class="hp-flex-none w-auto">
        <b-button
          @click="codeClick()"
          variant="text"
          class="btn-icon-only show-code-btn"
        >
          <i
            class="ri-code-s-slash-line hp-text-color-black-80 hp-text-color-dark-30 lh-1"
            style="font-size: 16px"
          ></i>
        </b-button>
      </b-col>

      <div class="col-12 mt-16">
        <div class="d-flex flex-wrap">
          <div class="mr-16 mb-16">
            <b-button variant="primary" disabled>
              <b-spinner small></b-spinner>
            </b-button>
          </div>

          <div class="mr-16 mb-16">
            <b-button variant="primary" disabled>
              <b-spinner small></b-spinner>
              <span class="sr-only">Loading...</span>
            </b-button>
          </div>

          <div class="mr-16 mb-16">
            <b-button variant="primary" disabled>
              <b-spinner small type="grow"></b-spinner>
            </b-button>
          </div>

          <div class="mr-16 mb-16">
            <b-button variant="primary" disabled>
              <b-spinner small type="grow" class="mr-8"></b-spinner>
              Loading...
            </b-button>
          </div>
        </div>
      </div>

      <div
        v-if="codeActive"
        class="col-12 mt-24 hljs-container"
        :class="{ active: codeActiveClass }"
      >
        <pre v-highlightjs>
          <code class="hljs html">
            {{ codeText }}
          </code>
        </pre>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BButton, BSpinner } from "bootstrap-vue";

import code from "./code";

export default {
  data() {
    return {
      codeText: code.buttons,
      codeActive: false,
      codeActiveClass: false,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
  },
  methods: {
    codeClick() {
      this.codeActive = !this.codeActive;

      setTimeout(() => {
        this.codeActiveClass = !this.codeActiveClass;
      }, 100);
    },
  },
};
</script>
